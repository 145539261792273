import { Link as ChakraLink, Box } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import { motion } from 'framer-motion';
import React from 'react';

export default function RouterLink({
  link,
  isExternal,
  href,
  children,
  variants,
  variant,
  ...restProps
}) {
  const MotionBox = motion(Box);

  return (
    <MotionBox variants={variants}>
      <ChakraLink
        variant={variant}
        _focus={{ outline: 'none' }}
        {...(isExternal
          ? { href: link }
          : {
              as: GatsbyLink,
              to: link,
              activeStyle: {
                color: '#00ff99',

                fontWeight: 700,
              },
            })}
        {...restProps}
      >
        {children}
      </ChakraLink>
    </MotionBox>
  );
}
