import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import Fonts from './src/fonts';
import theme from './src/theme';
import Layout from './src/components/LayoutEl';
export const wrapPageElement = ({ element }) => (
  <ChakraProvider theme={theme}>
    <Fonts />
    <AnimatePresence />
    <AnimateSharedLayout type='crossfade'>
      <Layout>{element}</Layout>
    </AnimateSharedLayout>
    <AnimatePresence />
  </ChakraProvider>
);

// Update Scroll position after page transition
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // transition duration from `layout.js` * 1000 to get time in ms
  // * 2 for exit + enter animation
  const TRANSITION_DELAY = 0.3 * 1000 * 2;
  // if it's a "normal" route
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY);
  }
  // if we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0];
    window.setTimeout(
      () => window.scrollTo(...savedPosition),
      TRANSITION_DELAY
    );
  }
  return false;
};
