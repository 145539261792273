/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import * as React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import Navigation from './Navigation';
import Footer from './FooterEl';
const Layout = ({ children }) => {
  return (
    <>
      <Navigation
        navBg='brand.black'
        linkKey='nav-link'
        links={[
          {
            linkText: 'Home',
            link: '/',
            isExternal: false,
          },

          {
            linkText: 'Über uns',
            link: '/ueberuns',
            isExternal: false,
          },
          {
            linkText: 'Das Team',
            link: '/team/',
            isExternal: false,
          },
          {
            linkText: 'Leistungen',
            link: '/leistungen/',
            isExternal: false,
          },

          {
            linkText: 'Jobs',
            link: '/stellen',
            isExternal: false,
          },

          {
            linkText: 'Kontakt',
            link: '/kontakt',
            isExternal: false,
          },
        ]}
        linkKey1='nav-info'
        links1={[
          {
            linkText: 'Home',
            link: '/',
            isExternal: false,
          },

          {
            linkText: 'Datenschutz',
            link: '/datenschutz',
            isExternal: false,
          },
          {
            linkText: 'Impressum',
            link: '/impressum',
            isExternal: false,
          },
          {
            linkText: 'Kontakt',
            link: '/kontakt',
            isExternal: false,
          },
        ]}
      />

      <main>{children}</main>

      <Footer
        linkKey='nav-link'
        links={[
          {
            linkText: 'Home',
            link: '/',
            isExternal: false,
          },

          {
            linkText: 'Datenschutz',
            link: '/datenschutz',
            isExternal: false,
          },
          {
            linkText: 'Hinweisgeberschutz',
            link: 'https://kdw-hr.trusty.report',
            isExternal: true,
          },
          {
            linkText: 'Impressum',
            link: '/impressum',
            isExternal: false,
          },
          {
            linkText: 'Kontakt',
            link: '/kontakt',
            isExternal: false,
          },
        ]}
        bgColor={'brand.gray.100'}
        textColor={'brand.yellow'}
      />
    </>
  );
};

export default Layout;
