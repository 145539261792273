import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },

  fonts: {
    heading: 'kdwnormal, sans-serif',
    body: 'kdwnormal, sans-serif',
  },
  sizes: {
    container: {
      xxl: '1600px',
      xl: '1440px',
    },
  },
  breakpoints: {
    xxl: '1600px',
    xl: '1440px',
  },

  lineHeights: {
    undercut: '0.8',
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
    3: '.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 'normal',
        lineHeight: 'undercut',
        letterSpacing: 'tight',
      },
    },
    Text: {
      baseStyle: {
        fontWeight: 'normal',
        lineHeight: 'shorter',
        letterSpacing: 'tight',
        breakWord: 'break-word',
        overflowWrap: 'break-word',
      },
    },

    Button: {
      baseStyle: {
        borderRadius: '1000px',
        whiteSpace: 'wrap',
      },
      variants: {
        solid: {
          borderRadius: '1000px',

          display: 'block',
          fontSize: 'xs',
          letterSpacing: 'wide',
          transition: 'all .25s ease-in-out',
          _hover: {
            background: 'brand.gray.black',
            color: 'brand.gray.1000',
            transition: 'all .125s ease-in-out',
          },
        },
        ghost: {
          borderRadius: 'none',
          paddingRight: '3rem',
          display: 'block',
          fontSize: 'xs',
          letterSpacing: 'wider',
          transition: 'all .25s ease-in-out',
          border: '1px solid',
          _hover: {
            background: 'transparent',
            transition: 'all .125s ease-in-out',
          },
        },
      },
    },

    Container: {
      baseStyle: {
        maxW: { base: 'calc(100% - 2rem)', lg: '8xl' },
        whiteSpace: 'wrap',
      },
      variants: {
        layoutContainer: {
          boxSizing: 'border-box',
          py: 0,
          px: 0,
        },
        layoutContainer2: {
          maxW: { base: 'calc(100% - 2rem)', lg: '6xl' },
          boxSizing: 'border-box',
          py: 0,
          px: 0,
        },
      },
    },
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: 'none',
        },
      },
      variants: {
        navlink: {
          textDecoration: 'none',
          _hover: { textDecoration: 'underline' },
        },
      },
    },
  },
  colors: {
    brand: {
      blue: '#00B4E6',
      green: '#3CC896',
      red: '#E60064',
      yellow: '#FABE64',
      violet: '#6464B4',
      gray: {
        black: '#000000',
        100: '#202025',
        300: '#3c3c3c',
        500: '#666666',
        700: '#999999',
        800: '#aaaaaa',
        900: '#e6e6e6',
        1000: '#f9f9f9',
        white: '#ffffff',
      },
      warn: '#ff0000',
    },
  },
});

export default theme;
