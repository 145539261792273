import { Box, Container, Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import RouterLink from "./Utility/Routing/RouterLink";

export default function Footer({
  links,
  linkKey,
  links1,
  linkKey1,
  links3,
  linkKey3,
  links2,
  linkKey2,
  textColor,
  bgColor,
}) {
  const MotionBox = motion(Box);
  const containerVariants = {
    initial: {
      width: "0%",
      opacity: 0,
    },
    animate: {
      width: "100%",
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        duration: 0.3,
        when: "beforeChildren",
      },
    },
  };
  const childVariants = {
    initial: {
      y: 10,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
  };
  return (
    <Container
      as="footer"
      w="100%"
      maxW="100%"
      p={{ base: 2, lg: 24 }}
      bg={bgColor}
    >
      <Container variant="layoutContainer" >
        <Flex alignItems="start" flexWrap={"wrap"}>
          <Flex flexWrap={"wrap"} minW={{ base: "100%", md: "24px" }} my={{base:"3rem",lg:"0"}} justifyContent={{base:"start",md:"start"}}>
            <RouterLink isExternal={false} link={"/"}>
            <svg width="80" height="80" viewBox="0 0 350 118" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_26_12)">
<path d="M0 93.2167V15.5154H11.3594V59.7861H13.1291L34.572 38.4783H49.7178L22.2248 64.9993L50.6232 93.2167H35.6009L13.1703 70.4607H11.4005V93.2167H0Z" fill="#3CC896"/>
<path d="M86.718 94.7889C82.2319 94.7889 78.075 93.6718 74.2474 91.4376C70.4198 89.2034 67.333 86.0175 65.0282 81.8387C62.7234 77.6599 61.5299 72.6122 61.5299 66.6957V65.0407C61.5299 59.2069 62.6823 54.1592 64.9459 49.939C67.2095 45.7188 70.2963 42.4916 74.1239 40.2574C77.9515 38.0232 82.1496 36.9474 86.718 36.9474C90.2575 36.9474 93.2208 37.4025 95.6079 38.2714C97.9951 39.1403 99.9706 40.2988 101.535 41.6641C103.057 43.0295 104.251 44.4362 105.074 45.9257H106.844V15.5154H118.203V93.2167H107.05V85.4383H105.28C103.881 87.7966 101.782 89.9481 98.9417 91.8927C96.1018 93.8373 92.0273 94.7889 86.718 94.7889ZM89.9283 84.7763C94.8671 84.7763 98.9417 83.2041 102.111 80.0182C105.321 76.8324 106.926 72.2812 106.926 66.3647V65.3717C106.926 59.5379 105.321 55.0281 102.193 51.8422C99.024 48.6564 94.9494 47.0842 89.9283 47.0842C84.9071 47.0842 81.0383 48.6978 77.8281 51.8422C74.6178 55.0281 73.0127 59.5379 73.0127 65.3717V66.3647C73.0127 72.2812 74.6178 76.8324 77.8281 80.0182C81.0383 83.2041 85.0717 84.7763 89.9283 84.7763V84.7763Z" fill="#3CC896"/>
<path d="M146.396 93.2167L137.135 38.4783H148.536L155.368 85.5624H157.138L166.645 38.4783H185.083L194.467 85.5624H196.237L203.069 38.4783H214.429L205.168 93.2167H186.071L176.687 46.2567H174.918L165.534 93.2167H146.437H146.396Z" fill="#3CC896"/>
<path d="M308.342 115.43C339.364 105.984 356.895 73.0464 347.5 41.8605C338.104 10.6747 305.339 -6.94952 274.317 2.49574C243.295 11.941 225.763 44.879 235.159 76.0648C244.555 107.251 277.32 124.875 308.342 115.43Z" fill="#FABE64"/>
<path d="M288.223 80.1424H282.461V64.0063H266.821V80.1424H261.101V42.6985H266.821V58.7518H282.461V42.6985H288.223V80.1424Z" fill="white"/>
<path d="M324.071 53.2903C324.071 55.6487 323.495 57.5105 322.384 58.9172C321.272 60.324 319.914 61.317 318.309 61.8962V62.7651C319.667 62.8478 320.737 63.3029 321.56 64.1304C322.384 64.9579 322.795 66.1578 322.795 67.73V80.1424H316.992V68.5161C316.992 67.5645 316.745 66.7784 316.251 66.2405C315.757 65.7027 314.975 65.3717 313.864 65.3717H302.546V80.1424H296.784V42.6985H312.424C314.728 42.6985 316.745 43.1122 318.515 43.8983C320.285 44.7258 321.643 45.8429 322.631 47.3324C323.618 48.8219 324.071 50.601 324.071 52.6697V53.2489V53.2903Z" fill="white"/>
<path d="M311.806 47.953H302.546V60.1585H311.806C313.864 60.1585 315.469 59.6206 316.58 58.5863C317.733 57.5105 318.268 56.1038 318.268 54.2833V53.8696C318.268 52.0491 317.692 50.601 316.58 49.5666C315.469 48.5323 313.864 47.9944 311.765 47.9944L311.806 47.953Z" fill="#FABE64"/>
</g>
<defs>
<clipPath id="clip0_26_12">
<rect width="350" height="118" fill="white"/>
</clipPath>
</defs>
</svg>
            </RouterLink>
          </Flex>
          <Flex
            alignItems="start"
            flexWrap={"wrap"}
            minW={{ base: "100%", lg: "30%" }}
          >
            <MotionBox
              flex="1"
              ml={12}
              variants={containerVariants}
              initial="initial"
              animate="animate"
              pb={4}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"flex-end"}
            >
              {links.map((link, index) => (
                <MotionBox variants={childVariants} key={linkKey + index}>
                  <RouterLink
                    isExternal={link.isExternal}
                    link={link.link}
                    color="brand.gray.1000"
                  >
                    {link.linkText}
                  </RouterLink>
                </MotionBox>
              ))}
            </MotionBox>

            
          </Flex>
         
        </Flex>
      </Container>
    </Container>
  );
}
